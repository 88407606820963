import { useEffect, useRef, useState } from "react";
import { Text as GText, Rect } from '@antv/g';
import { OrganizationChart, CollapseExpandIcon, measureTextSize } from "@ant-design/graphs";
import TwoNode from "../page/nodes/TwoNode";
import { message } from "antd";
import AddItem from './addItem';
import axios from "axios";
import {
    BaseTransform,
    ExtensionCategory,
    register,
    treeToGraphData,
} from '@antv/g6';
import { useParams } from "react-router-dom"
import React from "react";
import { retry } from "@reduxjs/toolkit/query";
const { PlusMinusIcon, ArrowCountIcon } = CollapseExpandIcon;

const RootNodeStyle = {
    fill: '#EFF0F0',
    labelFill: '#262626',
    labelFontSize: 16,
    labelFontWeight: 600,
    labelPlacement: 'center',
    ports: [{ placement: 'right' }, { placement: 'left' }],
    radius: 4,
};

const NodeStyle = {
    fill: 'transparent',
    labelPlacement: 'center',
    labelFontSize: 12,
    ports: [{ placement: 'right-bottom' }, { placement: 'left-bottom' }],
};


let textShape;
const measureText = (text) => {
    if (!textShape) textShape = new GText({ style: text });
    textShape.attr(text);
    return textShape.getBBox().width;
};
const fontSize = 20

const getNodeWidth = (name) => measureText({ text: name, fontSize: fontSize }) + 20;

function MindShowComponentTwo(props) {
    const { id } = useParams()
    const rootId = id;
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [manageId, setManageId] = useState(null);
    const [currentNode, setCurrentNode] = useState({});
    const [messageApi, contextHolder] = message.useMessage();
    const { data = {}, isEdit = false, getData } = props;


    // let graph = null;
    const contentRef = useRef();
    const deletedNode = (id) => {
        axios({
            url: "/api/deleted",
            method: "GET",
            params: {
                id: id
            }
        }).then(res => {
            if (res.data) {
                messageApi.open({
                    type: 'success',
                    content: '操作成功',
                });
                getData && getData()
                // contentRef.current && contentRef.current.removeNodeData(id);
            }
        }).catch(error => { console.log(error) })
    }

    const options = {
        direction: 'horizontal',
        padding: 40,
        autoFit: 'view',
        // type: 'boxed',
        // autoResize: true,
        animation: false,
        node: {
            // style: {
            //     component: (d) => {
            //         const { name, position, status } = d || {};
            //         const isActive = d.states?.includes('active');
            //         return <OrganizationChartNode data={d} name={name} position={position} status={status} isActive={isActive} />;
            //     },
            //     size: [200, 50],

            // },
            type: "react",
            style: (d) => {
                const style = {
                    component: <TwoNode data={d} fontSize={fontSize} isEdit={isEdit} setCurrentNode={setCurrentNode} deletedNode={deletedNode} setIsAddModalOpen={setIsAddModalOpen} manageId={manageId} setManageId={setManageId} />,
                    // ports: [{ placement: 'left' }, { placement: 'right' }],
                };
                // 设置节点宽度
                // const size = {
                //     "0": [d.name.length * 25 + 20, 60],
                //     "1": [d.name.length * 25 + 20, 50],
                // }[d.depth] || [d.name.length > 7 ? 180 : d.name.length * 25, 40]; 
                const size = {
                    "0": [getNodeWidth(d.name) + 20, 60],
                    "1": [getNodeWidth(d.name) + 20, 50],
                }[d.depth] || [getNodeWidth(d.name), 40];
                // let size = measureTextSize(d.name, [24, 16])
                if (d.depth === 0 && d.fileName && d.fileName.length > 0) {
                    size[0] = size[0] + 30
                    size[1] = size[1] + 30
                }
                if (d.depth > 0 && size[0] > 170 && d.children && d.children.length > 0) {
                    size[0] = 170
                } else if (d.fileName) {
                    size[0] = size[0] + 30
                }
                if (d.depth !== 0) {
                    Object.assign(style, {
                        size,
                        dx: -40,// 这个可以控制节点位置
                        dy: -size[1] / 2,
                    });
                } else {
                    Object.assign(style, {
                        size,
                        dx: -size[0] / 2 + 40,
                        dy: -100,
                    });
                }
                return style;
            },
        },
        edge: {

            style: {
                stroke: '#873bf4',
                radius: 16,
                lineWidth: 2,
                endArrow: true,
            },
        },
        // plugins: [
        //     // { key: 'fisheye', type: 'fisheye', nodeStyle: { label: true, icon: true } },
        //     {
        //         type: 'tooltip',
        //         trigger: 'hover',
        //         // enable: (event) => event.targetType === 'node',
        //         getContent: (e, items) => {
        //             let result = '';
        //             items.forEach((item) => {
        //                 result += `<p>${item.name}</p>`;
        //             });
        //             return result;
        //         },
        //     },
        //     {
        //         type: 'toolbar',
        //         position: 'top-left',
        //         onClick: (item) => {

        //             console.log(contentRef.current)
        //             // const current = contentRef.current.getNodeData(item.data.id);
        //             switch (item.id) {
        //                 case 'zoom-in':
        //                     contentRef.current.zoom(1.2);
        //                     break;
        //                 case 'zoom-out':
        //                     contentRef.current.zoom(1 / 1.2);
        //                     break;
        //                 case 'redo':
        //                     contentRef.current.redo();
        //                     break;
        //                 case 'undo':
        //                     contentRef.current.undo();
        //                     break;
        //                 // case 'edit':
        //                 //     // 处理编辑逻辑
        //                 //     alert('Edit action');
        //                 //     break;
        //                 // case 'delete':
        //                 //     // 处理删除逻辑
        //                 //     alert('Delete action');
        //                 //     break;
        //                 // case 'auto-fit':
        //                 //     contentRef.current.fitView();
        //                 //     break;
        //                 // case 'export':
        //                 //     // 处理导出逻辑
        //                 //     contentRef.current('Export action');
        //                 //     break;
        //                 case 'reset':
        //                     contentRef.current.reset();
        //                     break;
        //                 default:
        //                     break;
        //             }
        //             // contentRef.current
        //             alert('item clicked:' + item);
        //         },
        //         getItems: () => {
        //             // G6 内置了 9 个 icon，分别是 zoom-in、zoom-out、redo、undo、edit、delete、auto-fit、export、reset
        //             return [
        //                 { id: 'zoom-in', value: 'zoom-in' },
        //                 { id: 'zoom-out', value: 'zoom-out' },
        //                 { id: 'redo', value: 'redo' },
        //                 { id: 'undo', value: 'undo' },
        //                 // { id: 'edit', value: 'edit' },
        //                 // { id: 'delete', value: 'delete' },
        //                 // { id: 'auto-fit', value: 'auto-fit' },
        //                 // { id: 'export', value: 'export' },
        //                 { id: 'reset', value: 'reset' },
        //             ];
        //         },
        //     },],
        behaviors: [
            'scroll-canvas',
            'hover-activate',
            {
                type: 'hover-activate', enable: (event) => event.targetType === 'node'
            },
            'zoom-canvas',
            'collapse-expand',
            {
                type: 'click-select', enable: (event) => event.targetType === 'node'
            },
        ],
        // transforms: (transforms) => [
        //     ...transforms.filter((transform) => (transform).key !== 'assign-color-by-branch'),
        //     {
        //         ...(transforms.find((transform) => (transform).key === 'assign-color-by-branch') || {}),
        //         colors: ['rgb(78, 121, 167)', 'rgb(242, 142, 44)', 'rgb(225, 87, 89)']
        //     },
        // ],
        // layout: {
        //     type: 'antv-dagre',
        //     nodesep: -10,
        //     ranksep: 80,
        // },
        layout: {
            type: 'indented',
            // direction: 'LR',
            rankDir: "LR",
            dropCap: false,
            nodeSep: 10,

            indent: (d) => {

                // if (d.name.length > 8) return d.name.length * 25 + 20;
                return 220;
            },
            nodesepFunc: (d) => {
                //每个节点的水平间隙
                // d 是一个节点
                // if (d.id === 'testId') return 50;
                return 15;
            },

            getVGap: () => {
                // 各节点之间的间距
                return 10;
            },
            // getHGap: () => {
            //   // 连接线曲滑度 值越大线条越弯曲
            //   return 1;
            // },

            getSide: () => {
                return 'left';
            },
            getWidth: (d) => {
                // d 是一个节点
                console.log()
                return 20;
            },

            getHeight: () => 40,
        },
        transforms: (prev) => [
            ...prev.filter((transform) => transform.type !== 'collapse-expand-react-node'),
            {
                ...prev.find((transform) => transform.type === 'collapse-expand-react-node'),
                enable: prev.type === "node",
            },
            // ...prev.filter((transform) => (transform).key !== 'assign-color-by-branch'),
            // {
            //     ...(prev.find((transform) => (transform).key === 'assign-color-by-branch') || {}),
            //     colors: ['rgb(78, 121, 167)', 'rgb(242, 142, 44)', 'rgb(225, 87, 89)']
            // },

        ],




    }
    if (data.id) options.data = treeToGraphData(data);



    return (<div style={{ height: '95vh' }} >
        {/* <div id="container" style={{ height: '100vh' }}></div> */}
        <OrganizationChart {...options} ref={contentRef} />
        <AddItem callBack={(isflush) => { setIsAddModalOpen(false); setCurrentNode({}); if (isflush) getData && getData() }} data={currentNode} isAddModalOpen={isAddModalOpen}></AddItem>
    </div >);
}

export default MindShowComponentTwo;


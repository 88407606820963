
import MindShowComponent from "../component/MindShowComponent";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import MindShowComponentTwo from "./MindComponentTwo";

function ShowPage(props) {
    const { id } = useParams()
    const [data, setData] = useState({})
    useEffect(() => {
        axios({
            method: "GET",
            url: "/api/getByIdNew",
            params: { id }
        }).then(res => {
            setData(res.data);
        }).catch(error => { console.log(error) })
    }, [])

    return (
        <MindShowComponentTwo data={data} isEdit={false} />
    )
}

export default ShowPage;
import MainLayout from "./MainLayout";

function App() {

  return(

      <MainLayout/>
  )
}

export default App;

import {
    Button,
    ColorPicker,
    Form,
    Input,
    message,
    Modal,
    Select,
    Upload
} from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import UploadFile from "../component/UploadFile";
const formItemLayout = {
    labelCol: {
        xs: {
            span: 12,
        },
        sm: {
            span: 4,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 14,
        },
    },
};

const AddItem = (props) => {
    const { callBack, isAddModalOpen, data = {} } = props;
    const [messageApi] = message.useMessage();
    const [form] = Form.useForm();
    // const [selectedColor, setSelectedColor] = useState('#1890ff'); // 初始颜色值
    // const [fontColor, setFontColor] = useState('#000');
    const submitdata = (value) => {
        let submitData = { ...value, id: props.data.id, parentId: props.data.parentid, showType: value.showType }
        if (value.fileList && value.fileList[0]) {
            submitData.fileName = value.fileList[0].response
        } else {
            submitData.fileName = "";
        }
        if (value.color && value.color.toHexString) submitData.color = value.color.toHexString();
        if (value.fontColor && value.fontColor.toHexString) submitData.fontColor = value.fontColor.toHexString();
        axios({
            method: "POST",
            url: "/api/addOrUpdate",
            data: submitData
        }).then(res => {
            if (res.data) {
                messageApi.open({
                    type: 'success',
                    content: '操作成功',
                });
            }
            callBack && callBack(true)
            form.resetFields();
        }).catch(error => { console.log(error) })
    }
    useEffect(() => {
        if (data.fileName && data.fileName.length > 0) {
            data.fileList = [{
                status: 'done',
                response: data.fileName
            }]
        }
        form.setFieldsValue({ ...data });
    }, [data])



    return (
        <Modal title={data.id ? "编辑节点" : "新增子节点"} open={isAddModalOpen} width={"40%"} onOk={() => form.submit()} onCancel={() => { form.resetFields(); callBack && callBack(false) }}
            okText={'提交'} cancelText={'关闭'} centered={true} >
            <div>
                <Form
                    {...formItemLayout}
                    layout={'horizontal'}
                    form={form}
                    onFinish={submitdata}
                // data={data}
                >
                    <Form.Item label="名称" name={'name'} rules={[
                        {
                            required: true,
                            message: "名称不能为空"
                        },
                    ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="文字颜色" name={'fontColor'}>
                        <ColorPicker defaultValue="#444445" size="small" showText />
                    </Form.Item>
                    <Form.Item label="背景颜色" name={'color'} >
                        <ColorPicker defaultValue="#2574e2" size="small" showText />
                    </Form.Item>
                    <Form.Item label="风格" name={'showType'}  >
                        <Select ><Select.Option value="text">文本</Select.Option><Select.Option value="fg">方格</Select.Option></Select>
                    </Form.Item>
                    <Form.Item label="附件" name="fileList">
                        <UploadFile max={1}></UploadFile>
                    </Form.Item>

                </Form>
            </div>
        </Modal >
    );
}
export default AddItem;
import React, { Component } from "react"
import { Upload, message, Icon, Modal, Image } from "antd"
import { CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";

const downHttp = "/api/download/";
class UploadImg extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fileList: [],
            previewVisible: false,
            previewImage: "",
        }
    }

    handlePreview = async (file) => {
        this.setState({
            previewImage: file.url,
            previewVisible: true,
        })
    }

    componentWillReceiveProps(props) {
        console.log(props);
        if (props.value) {
            if (props.value.length === 0) {
                this.setState({ fileList: [] })
            } else {
                let fileList = []
                props.value.map((item, index) => {
                    fileList.push({
                        uid: item.response,
                        name: item.response,
                        status: 'done',
                        url: `${downHttp}${item.response}`,
                        response: item.response
                    })
                    return null
                })
                this.setState({ fileList })
            }
        } else {
            this.setState({ fileList: [] })
        }
    }
    handleChange = (info) => {
        let fileList = [...info.fileList]
        fileList = fileList.map((file, index) => {
            if (file.response) {
                file.name = file.response;
                file.url = `${downHttp}${file.response}`;
                file.id = file.response
            }
            return file
        })
        this.setState({ fileList })
        if (info.file.status === "done") {
            let allDone = true
            fileList.map((item) => {
                if (item.status !== "done") allDone = false
                return null
            })
            message.info("文件上传成功");
            allDone && this.props.onChange(fileList)
        } else if (info.file.status === "error") {
            message.warning("文件上传失败，请先点击文件右边删除再重新上传")
        }
    }

    render() {
        const { previewVisible, previewImage, fileList } = this.state
        const { noUpload = false, max = 1 } = this.props
        const uploadButton = (
            <button style={{ border: 0, background: 'none' }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>上传</div>
            </button>
        );
        return (
            <>
                <Upload
                    type="file"
                    accept={"image/*"}
                    action="/api/upload"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={(info) => this.handleChange(info)}
                    disabled={this.props.disabled}
                    onRemove={(e) => {
                        let delIndex = "-1"
                        fileList.map((item, index) => {
                            item.id = item.response
                            if (item.response === e.response) {
                                delIndex = index
                            }
                            return null
                        })
                        if (delIndex !== "-1") {
                            fileList.splice(delIndex, 1)
                        }
                        this.setState({ fileList })
                        this.props.onChange(fileList)
                    }}
                    onPreview={this.handlePreview}
                >
                    {(fileList.length < max) && uploadButton}
                </Upload>
                {
                    previewImage && (
                        <Image
                            wrapperStyle={{ display: 'none' }}
                            preview={{
                                visible: previewVisible,
                                onVisibleChange: (visible) => { this.setState({ previewVisible: visible }) },
                                afterOpenChange: (visible) => !visible && this.setState({ previewImage: "" }),
                            }}
                            src={previewImage}
                        />
                    )
                }
            </>
        )
    }
}

export default UploadImg

import QRCodeComponent from "../component/QRCodeComponent";
import {
    Avatar,
    Button,
    Col,
    FloatButton,
    Form,
    Input,
    List,
    message,
    Modal,
    QRCode,
    Radio,
    Row,
    Skeleton,
    Space
} from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import UploadImg from "../component/UploadImg";
import AddMenu from "./addMenu";


const MenuPage = () => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [currentData, setCurrentNode] = useState({})
    const [total, setTotal] = useState(10);
    const [qrCodeData, setQrCodeData] = useState("123");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const showModal = (id) => {
        setQrCodeData("http://www.bbahh.cn/show/" + id);
        setIsModalOpen(true);
    };
    const handleOk = () => {
        const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
        if (canvas) {
            const url = canvas.toDataURL();
            doDownload(url, 'QRCode.png');
        }
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const doDownload = (url, fileName) => {
        const a = document.createElement('a');
        a.download = fileName;
        a.href = url;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    const showEditModal = (editInfo) => {
        setCurrentNode(editInfo)
        setIsAddModalOpen(true);

    };
    const showAddModal = () => {
        setCurrentNode({})
        setIsAddModalOpen(true);

    };
    const [messageApi, contextHolder] = message.useMessage();
    const getData = () => {
        const params = {
            page
        }
        axios({
            url: "/api/getMenu",
            method: "GET",
            params: params
        }).then(res => {
            setTotal(res.data.total);
            setData(res.data.records);
        }).catch(error => { console.log(error) })
    }
    const deleteById = (id) => {
        const params = {
            id
        }
        axios({
            url: "/api/deleted",
            method: "GET",
            params: params
        }).then(res => {
            if (res.data) {
                messageApi.open({
                    type: 'success',
                    content: '操作成功',
                });
                getData()
            }
        }).catch(error => { console.log(error) })
    }
    useEffect(() => {
        getData();
    }, [page])
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 12,
            },
            sm: {
                span: 4,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };
    return (
        <>
            {contextHolder}
            <Row>
                <Col offset={23}>
                    <Button type={"primary"} onClick={() => { showAddModal() }}>新增</Button>
                </Col>
            </Row>
            <List
                style={{ margin: "20px" }}
                pagination={{
                    position: "bottom",
                    align: "center",
                    defaultCurrent: 1,
                    pageSize: 10,
                    total,
                    onChange: (page, pageSize) => {
                        setPage(page);
                    }
                }}
                dataSource={data}
                renderItem={(item, index) => (
                    <List.Item
                        actions={[<a key="list-loadmore-edit" href={"/edit/" + item.id}>打开</a>, <a key="list-loadmore-edit" onClick={() => { showEditModal(item) }}>修改</a>, <a key="list-loadmore-more" onClick={() => deleteById(item.id)}>删除</a>, <a key="list-loadmore-more" onClick={() => { showModal(item.id) }}>获取二维码</a>]}
                    >
                        <Skeleton avatar title={false} loading={item.loading} active>
                            <List.Item.Meta
                                title={item.name}
                            />
                        </Skeleton>
                    </List.Item>
                )}
            />
            <Modal title="二维码信息" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
                width={200} okText={'下载'} cancelText={'关闭'}>
                <div>
                    <Space id="myqrcode" direction="vertical">
                        <QRCode
                            type='canvas'
                            value={qrCodeData}
                            bgColor="#fff"
                            style={{
                                marginBottom: 16,
                            }}
                        />
                    </Space>
                </div>
            </Modal>
            <AddMenu callBack={(isflush) => { setIsAddModalOpen(false); if (isflush) setPage(1); getData() }} data={currentData} isAddModalOpen={isAddModalOpen}></AddMenu>
        </>
    );
}
export default MenuPage;
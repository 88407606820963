
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import MindShowComponentTwo from "./MindComponentTwo";

function ManagerPage(props) {
    const { id } = useParams()
    const [data, setData] = useState({})
    useEffect(() => {
        getData()
    }, [id])
    const getData = () => {
        axios({
            method: "GET",
            url: "/api/getByIdNew",
            params: { id }
        }).then(res => {
            setData(res.data);
        }).catch(error => { console.log(error) })
    }

    return (
        <MindShowComponentTwo data={data} getData={getData} isEdit={true} />
    )
}

export default ManagerPage;
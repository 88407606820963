import {Avatar, Button, Card, Col, Row, Tag, Typography} from 'antd';
import { ExtensionCategory,register } from '@antv/g6';
import { ReactNode } from '@antv/g6-extension-react';
import React from "react";

const { Text } = Typography;

register(ExtensionCategory.NODE, 'react', ReactNode);
const NodeItem = ({ data, onChange }) => {
  return (
      <div style={{border: '2px solid #000',borderRadius: '5px'}}>
          <div className="task-node" style={{border: `1px solid #fff)}`}}>
              <div className="task-node-title" style={{backgroundColor:'#91cc76'}}>
                  <Text className="task-node-name" ellipsis={{tooltip: true}}>
                      {data}
                  </Text>
              </div>
              <div>
                  <Button type='link' style={{width:'100%'}} onClick={()=>window.open("HTTP://WWW.HAO123.COM", '_blank')}>
                      打开
                  </Button>
              </div>

          </div>
      </div>
  );
};
export default NodeItem;
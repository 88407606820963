
import {
    Button,
    ColorPicker,
    Form,
    Input,
    message,
    Modal,
    Select,
    Upload
} from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import UploadFile from "../component/UploadFile";
import UploadImg from "../component/UploadImg";
const formItemLayout = {
    labelCol: {
        xs: {
            span: 12,
        },
        sm: {
            span: 4,
        },
    },
    wrapperCol: {
        xs: {
            span: 24,
        },
        sm: {
            span: 14,
        },
    },
};

const AddMenu = (props) => {
    const { callBack, isAddModalOpen, data = {} } = props;
    const [messageApi] = message.useMessage();
    const [form] = Form.useForm();
    // const [selectedColor, setSelectedColor] = useState('#1890ff'); // 初始颜色值
    // const [fontColor, setFontColor] = useState('#000');
    const submitdata = (value) => {
        let submitData = { ...value, id: props.data.id, showType: value.showType }
        if (value.fileList && value.fileList[0]) {
            submitData.fileName = value.fileList[0].response
        } else {
            submitData.fileName = null;
        }
        axios({
            method: "POST",
            url: "/api/addOrUpdate",
            data: submitData
        }).then(res => {
            if (res.data) {
                messageApi.open({
                    type: 'success',
                    content: '操作成功',
                });
            }
            callBack && callBack(true)
            form.resetFields();
        }).catch(error => { console.log(error) })
    }
    useEffect(() => {
        if (data.fileName) {
            data.fileList = [{
                status: 'done',
                response: data.fileName
            }]
        }
        form.setFieldsValue({ ...data });
    }, [data])



    return (
        <Modal title={data.id ? "编辑" : "新增"} open={isAddModalOpen} width={500} onOk={() => form.submit()} onCancel={() => { form.resetFields(); callBack && callBack(false) }}
            okText={'提交'} cancelText={'关闭'} centered={true} >
            <Form
                {...formItemLayout}
                layout={'horizontal'}
                form={form}
                onFinish={submitdata}
            // initialValues={currentData}

            >
                <Form.Item label="数据id" name={'id'} hidden={true} >
                    <Input />
                </Form.Item>
                <Form.Item label="名称" name={'name'} rules={[
                    {
                        required: true,
                        message: "名称不能为空"
                    },
                ]}>
                    <Input />
                </Form.Item>
                <Form.Item label="图片" name={'fileList'}  >
                    <UploadImg max={1} />
                </Form.Item>
            </Form>
        </Modal >
    );
}
export default AddMenu;
import { Route, Routes } from "react-router-dom";
import ShowPage from "./page/ShowPage";
import ManagerPage from "./page/ManagerPage";
import MenuPage from "./page/MenuPage";
const MainRouter = () => {
    return (
        <Routes>
            <Route path="/" element={<MenuPage />} />
            <Route path="/edit/:id" element={<ManagerPage />} />
            <Route path="/show/:id" element={<ShowPage />} />
            {/* <Route path="/manage" element={<MindShowComponent2 />} /> */}
        </Routes>
    )
}
export default MainRouter;

import { Button, Flex, Modal, Space, Typography } from 'antd';
import { ExtensionCategory, Graph, register } from '@antv/g6';
import { GNode, Group, Image, Rect, Text } from '@antv/g6-extension-react';
import { BugOutlined, FilePdfTwoTone, PlusCircleTwoTone, PlayCircleTwoTone, EditTwoTone, DeleteTwoTone, FileImageTwoTone } from '@ant-design/icons';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import "./index.css"

const COLOR_MAP = {
    '0': '#3fc1c9',
    '1': '#8983f3',
    "2": '#f48db4',
    "3": '#ffaa64',
};
const ACTIVE_COLOR = '#f6c523';

const downHttp = "/api/download/";
register(ExtensionCategory.NODE, 'react', GNode);
const TwoNode = ({ data, isEdit, setCurrentNode, setIsAddModalOpen, setManageId, deletedNode, manageId, fontSize }) => {

    const { name, depth, fileName, color, fontColor, showType, id, children } = data;
    const [isPlaying, setIsPlaying] = useState(true);

    const handleCancel = () => {
        isPlaying && setIsPlaying(false);
        setVisible(false);
    };



    const isHovered = data.states?.includes('active');
    const isSelected = data.states?.includes('selected');
    const color1 = color || COLOR_MAP[depth];
    const fontColor1 = fontColor || "black";

    const [visible, setVisible] = useState(false)
    const background = () => {
        let backgroundVal = (showType === "fg" || depth == 0) && color1
        if (depth == 0 && fileName) {
            backgroundVal = `url(${downHttp}${fileName}) no-repeat center center / cover`;
        }
        return backgroundVal;

    }
    const border = () => {
        let borderVal = (showType === "fg" || depth === 0) && `3px solid ${color1}`
        if (depth === 0 && fileName && fileName.length > 0) {
            borderVal = null;
        }
        return borderVal;
    }

    const containerStyle = {
        width: "100%",
        height: '100%',
        color: fontColor1,
        background: background(),
        border: border(),
        borderRadius: 16,
        cursor: 'pointer',
    };

    if (isHovered) {
        isEdit && manageId !== id && setManageId(id)
        Object.assign(containerStyle, { border: `3px solid #000` });
    }
    //
    return (
        <>
            <Flex style={containerStyle} align="center" justify="center" onClick={() => { depth !== 0 && fileName && fileName.length > 0 && setVisible(true) }}>
                <Flex vertical style={{
                    padding: (depth === 0 || depth === 1) ? '8px 16px' : '3px', textAlign: "cetner"
                }} align="center" justify="center">
                    {(depth === 0 && (!fileName || fileName.length === 0)) && <BugOutlined style={{ fontSize: 24, marginBottom: 8 }} />}
                    < span style={{ fontWeight: (showType === "fg" || depth === 0) && 600, fontSize: fontSize }}>{(depth !== 0 || (depth === 0 && (!fileName || fileName.length === 0))) && name}
                        {depth !== 0 && fileName && (fileName.endsWith(".pdf") ? <FilePdfTwoTone onClick={() => { setVisible(true) }} style={{ fontSize: fontSize, marginLeft: 8 }} /> : fileName.match(/\.(jpe?g|png|gif|bmp|svg)$/i) ? <FileImageTwoTone onClick={() => { setVisible(true) }} style={{ fontSize: 15, marginLeft: 8 }} /> : < PlayCircleTwoTone onClick={() => { setVisible(true) }} style={{ fontSize: 15, marginLeft: 8 }} />)}</span>
                </Flex>
            </Flex >
            {
                isEdit && manageId === id && <Flex textAlign="center" justify="center">
                    <Space>
                        <PlusCircleTwoTone onClick={() => { setCurrentNode({ parentid: id }); setIsAddModalOpen(true) }} />
                        {depth !== 0 && <EditTwoTone onClick={() => { setCurrentNode(data); setIsAddModalOpen(true) }} />}
                        {depth !== 0 && (!children || children.length === 0) && <DeleteTwoTone onClick={() => { deletedNode(id) }} />}
                    </Space >
                </Flex>
            }
            <Modal open={visible} title="预览" onCancel={() => handleCancel(false)} centered={true} footer={null} width={"80%"} style={{ height: "600px" }} destroyOnClose={true} >
                {fileName && (fileName.endsWith('.pdf') ? <iframe src={`${downHttp}${fileName}`} width="100%" height="600px" style={{ border: 'none' }} /> :
                    (fileName.match(/\.(jpe?g|png|gif|bmp|svg)$/i) ? // 检查是否为图片文件
                        <img src={`${downHttp}${fileName}`} alt="图片" width="100%" height="auto" /> :
                        <ReactPlayer
                            url={`${downHttp}${fileName}`}
                            playing={isPlaying}
                            controls
                            width='100%'
                            height='600px'
                            onStart={() => setIsPlaying(true)}
                            onEnded={() => setIsPlaying(false)}
                        />))}
            </Modal>
        </>

    );
};


export default TwoNode;
import { Content, Header } from "antd/es/layout/layout";
import { Layout, Menu } from "antd";
import { BrowserRouter } from "react-router-dom";
import MainRouter from "./Router";
import mainpng from './images/main.png'

function ManagerPage(props) {

    return (
        <Layout>
            <Header style={{ height: '5vh' }}>
                <h2 style={{ color: '#fff', margin: "auto", lineHeight: "5vh" }}>码上学</h2>
            </Header>
            <Layout style={{
                height: '95vh', background: `url(${mainpng}) 0% 95% no-repeat`, backgroundSize: 'cover'
            }} >
                <Content>
                    <BrowserRouter>
                        <MainRouter />
                    </BrowserRouter>
                </Content>
            </Layout>
        </Layout >
    )
}

export default ManagerPage;